import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery, tabletMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const Section13 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const istablet = useMediaQuery(tabletMaxWidthMediaQuery);

    const blocks1 = [
        {
            type: "언론보도",
            title: "하이트진로, 테이블 오더 스타트업에 지분 투자",
            time: "2024. 06. 24",
            url: "https://www.asiatime.co.kr/article/20240624500319",
        },
        {
            type: "언론보도",
            title: "QR코드·태블릿 필요 없는 NFC 테이블오더 ‘태그히어’, 인건비 절감효과 기대",
            time: "2024. 03. 07",
            url: "https://www.kihoilbo.co.kr/news/articleView.html?idxno=1076623",
        },
    ];

    const blocks2 = [
        {
            type: "언론보도",
            title: "NFC 테이블오더 ‘태그히어’ 티엠알파운더스 프리 시리즈 A 투자유치…슈미트, 캡스톤파트너스 등 참여",
            time: "2024. 06. 13",
            url: "https://www.issuenbiz.com/news/articleView.html?idxno=44967",
        },
        {
            type: "언론보도",
            title: "태블릿 필요없는 테이블오더 ‘태그히어’ 약정·위약금 Zero 서비스 선보여",
            time: "2024. 06. 24",
            url: "https://www.itbiznews.com/news/articleView.html?idxno=115292",
        },
    ];
    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                width: isMobile ? "100%" : "1200px",
                maxWidth: "100vw",
                marginY: 0,
                paddingY: isMobile ? 16 : 20,
                gap: "40px",
                paddingX: istablet ? "30px" : "auto",
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    whiteSpace: "pre-line",
                    lineHeight: 1.5,
                    color: tmrColorPalette.grey[900],
                    letterSpacing: "-0.6px",
                    ...(isMobile && {
                        lineHeight: 1.3,
                    }),
                }}
            >
                {isMobile ? "태그히어 소식" : "언론 속 태그히어"}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        gap: "20px",
                    }}
                >
                    {blocks1.map((block) => (
                        <Box
                            onClick={() => {
                                block.url && window?.open(block.url)?.focus();
                            }}
                            key={block.title}
                            sx={{
                                backgroundColor: tmrColorPalette.background[100],
                                borderRadius: "20px",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                padding: "30px",
                                flex: 1,
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                variant={isMobile ? "f14SB" : "f15SB"}
                                sx={{
                                    lineHeight: 1.3,
                                    color: tmrColorPalette.grey[900],
                                }}
                            >
                                {block.type}
                            </Typography>
                            <Box sx={{ paddingTop: "5px" }} />
                            <Typography
                                variant={isMobile ? "f18B" : "f20B"}
                                sx={{
                                    lineHeight: 1.3,
                                    letterSpacing: "-0.4px",
                                    color: tmrColorPalette.grey[900],
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    ...(isMobile && {
                                        WebkitLineClamp: 2,
                                    }),
                                }}
                            >
                                {block.title}
                            </Typography>
                            <Box sx={{ paddingTop: "10px" }} />

                            <Typography
                                variant="f14M"
                                sx={{
                                    lineHeight: 1.3,
                                    color: tmrColorPalette.grey[400],
                                }}
                            >
                                {block.time}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",

                        gap: "20px",
                    }}
                >
                    {blocks2.map((block) => (
                        <Box
                            onClick={() => {
                                block.url && window?.open(block.url)?.focus();
                            }}
                            key={block.title}
                            sx={{
                                backgroundColor: tmrColorPalette.background[100],
                                borderRadius: "20px",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                padding: "30px",
                                flex: 1,
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                variant={isMobile ? "f14SB" : "f15SB"}
                                sx={{
                                    lineHeight: 1.3,
                                    color: tmrColorPalette.grey[900],
                                }}
                            >
                                {block.type}
                            </Typography>
                            <Box sx={{ paddingTop: "5px" }} />
                            <Typography
                                variant={isMobile ? "f18B" : "f20B"}
                                sx={{
                                    lineHeight: 1.3,
                                    letterSpacing: "-0.4px",
                                    color: tmrColorPalette.grey[900],
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    ...(isMobile && {
                                        WebkitLineClamp: 2,
                                    }),
                                }}
                            >
                                {block.title}
                            </Typography>
                            <Box sx={{ paddingTop: "10px" }} />

                            <Typography
                                variant="f14M"
                                sx={{
                                    lineHeight: 1.3,
                                    color: tmrColorPalette.grey[400],
                                }}
                            >
                                {block.time}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </LandingLayout>
    );
};

export default Section13;
